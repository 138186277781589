import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';

export interface FormDescriptionProps {
    description: string;
}

const FormDescription = ({ description }: FormDescriptionProps) => {

    const [open, setOpen] = useState<Boolean>(false)

    var textCharLimit = 50
    var shortDescription = description.slice(0, textCharLimit) + (description.length > textCharLimit ? '...' : '')

    return (
        <div className={open ? "d-flex flex-column" : "d-flex flex-row"}>
            <div className="d-flex">
                <InfoIcon fontSize="small"/>
                {open
                    ? <div className="fs-14 description-text" dangerouslySetInnerHTML={{ __html: description }}/>
                    : <div onClick={() => {setOpen(!open)}} className="fs-14 description-text" dangerouslySetInnerHTML={{ __html: shortDescription }}/>
                }
            </div>
            <a className="fs-14 cursor-pointer" onClick={() => {setOpen(!open)}}>
                <b>{description.length > textCharLimit && (open ? 'See Less' : 'See More')}</b>
            </a>
        </div>
    );
};

export default FormDescription;
