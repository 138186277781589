import React, { useEffect, useState } from "react";
import "./home.styles.css";
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { apiUrl } from "../../environment";
import PopupMessageComponent from "../../components/popup-message/popup-message";
import { coreService } from "../../services/core.service";

const initialFormValues = {
    username: "",
    password: "",
    showPassword: false,
};

const Home = () => {
    const [formValues, setFormValues] = useState(initialFormValues);
    const [formSubmit, setFormSubmit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userInfo, setUserInfo] = useState<any>(null);
    const [dialogMessage, setDialogMessage] = useState({
        title: "",
        content: "",
    });

    const emptyForm = !formValues.username || !formValues.password;

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleClickShowPassword = () => {
        setFormValues({
            ...formValues,
            showPassword: !formValues.showPassword,
        });
    };

    useEffect(() => {
        coreService.userData.subscribe((res) => {
            setUserInfo(res);
        });
    }, []);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setFormSubmit(true);
        axios
            .post(
                `${apiUrl}/authenticate`,
                {},
                {
                    auth: { username: formValues.username, password: formValues.password },
                }
            )
            .then((res) => {
                setFormSubmit(false);
                coreService.SaveTokenToLocalStorage(res.data.result.data.token);
                setFormValues(initialFormValues);
                window.location.assign("/dashboard");
            })
            .catch((error) => {
                setFormSubmit(false);
                setDialogMessage({
                    title: "Error",
                    content: error.response.data.result ? error.response.data.result.message : "There are some errors. Please try again.",
                });
                setDialogOpen(true);
            });
    };

    return (
        <div className="">
            {coreService.GetTokenFromLocalStorage() ? (
                <div className="position-center">Welcome{userInfo && userInfo.name && (", " + userInfo.name)}</div>
            ) : (
                <div className="form-container position-center mx-auto">
                    <div className="mb-4">Please Sign In</div>
                    <form>
                        <FormControl fullWidth variant="outlined" className="mb-4">
                            <InputLabel htmlFor="outlined-adornment-password">Username</InputLabel>
                            <OutlinedInput name="username" type="text" value={formValues.username} onChange={handleInputChange} label="Username" />
                        </FormControl>
                        {/* Password */}
                        <FormControl fullWidth variant="outlined" className="mb-4">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                name="password"
                                type={formValues.showPassword ? "text" : "password"}
                                value={formValues.password}
                                onChange={handleInputChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                            {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <Button className="w-100" variant="contained" size="large" onClick={handleSubmit} disabled={emptyForm || formSubmit}>
                            Sign In
                        </Button>
                    </form>
                </div>
            )}
            <PopupMessageComponent message={dialogMessage} open={dialogOpen} setOpen={setDialogOpen} />
        </div>
    );
};

export default Home;
