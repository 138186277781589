import React, { useState } from "react";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import ApiService from "../../services/api.service";

export interface Props {
    form_id: number | string | undefined;
    sortOptions: any[];
    setFilteredDatas: (filteredDatas: any) => void;
    setLoading: (loading: boolean) => void;
}

const TableSort = ({ form_id, sortOptions, setFilteredDatas, setLoading }: Props) => {
    const initialFormValues = {
        form_id: form_id,
        column_name: "",
        order: "",
    };

    const [sorting, setSorting] = useState<boolean>(false);
    const [formValues, setFormValues] = useState(initialFormValues);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSortSubmit = () => {
        // console.log(formValues);
        const { form_id, column_name, order } = formValues;
        setLoading(true);

        ApiService.orderDataByColumns(form_id, column_name, order).then(
            (res) => {
                setFilteredDatas(res.data.result.data);
                setLoading(false);
            },
            (err) => {
                console.error(err);
            }
        );
    };

    return (
        <div className="mx-1 d-flex align-items-center">
            <Button color="secondary" size="large" onClick={() => setSorting(!sorting)}>
                <SortIcon />
            </Button>
            {sorting && (
                <div>
                    <FormControl sx={{ width: 150 }} className="me-3" size="small">
                        <Select labelId="label_column_name" variant="standard" value={formValues.column_name ? formValues.column_name : 0} label="Sort by" onChange={handleInputChange} name="column_name">
                            <MenuItem value={0} disabled>
                                Sort by
                            </MenuItem>
                            {sortOptions &&
                                sortOptions.length &&
                                sortOptions.map((item: any) => {
                                    return (
                                        <MenuItem value={item.value} key={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: 80 }} className="me-3" size="small">
                        <Select labelId="label_order" variant="standard" value={formValues.order ? formValues.order : 0} label="Order" onChange={handleInputChange} name="order">
                            <MenuItem value={0} disabled>
                                Order
                            </MenuItem>
                            <MenuItem value="asc">Asc</MenuItem>
                            <MenuItem value="desc">Desc</MenuItem>
                        </Select>
                    </FormControl>
                    <Button color="secondary" size="small" variant="outlined" onClick={handleSortSubmit} disabled={!formValues.column_name || !formValues.order}>
                        Sort
                    </Button>
                </div>
            )}
        </div>
    );
};

export default TableSort;
