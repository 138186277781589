import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import ApiService from "../../services/api.service";

export interface SimpleDialogProps {
    open: boolean;
    setDialogOpen: (value: boolean) => void;
    data?: any;
}

const UserDialog = ({ setDialogOpen, open, data }: SimpleDialogProps) => {
    const [usersArray, setUsersArray] = useState<any[]>([]);
    const [formValues, setFormValues] = useState<{ [index: string]: any }>({});

    useEffect(() => {
        data && setUsersArray(data.admin_notifications);
    }, [data]);

    useEffect(() => {
        let initialFormValues: any = {};
        if (data && data.admin_notifications && data.admin_notifications.length) {
            for (let i = 0; i < data.admin_notifications.length; i++) {
                initialFormValues["email" + (i + 1)] = data.admin_notifications[i].email;
            }
        }
        setFormValues(initialFormValues);
    }, [data]);

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleCreateUser = () => {
        setUsersArray([...usersArray, { id: "", email: "", name: "" }]);
    };

    const handleSubmit = () => {
        const transmissionData = new URLSearchParams();

        transmissionData.append("id", data.id);

        for (const name in formValues) {
            if (formValues[name]) {
                transmissionData.append("email[]", formValues[name] ? formValues[name] : "");
            }
        }

        ApiService.updateFormUser(transmissionData).then(
            (res) => {
                setDialogOpen(false);
            },
            (error) => {
                console.error(error);
            }
        );
    };

    return (
        <Dialog onClose={() => setDialogOpen(false)} open={open} fullWidth maxWidth="sm">
            <DialogTitle>Manage Users</DialogTitle>
            <DialogContent>
                {usersArray.map((item: any, index: number) => {
                    return (
                        <div className="row my-4" key={index}>
                            <div className="col-4">Notification Email {index + 1}</div>
                            <div className="col-7">
                                <TextField fullWidth name={`email${index + 1}`} variant="standard" defaultValue={formValues.email + index + 1 ? formValues.email + index + 1 : item.email ? item.email : ""} onChange={handleFormChange} />
                            </div>
                        </div>
                    );
                })}
                <div className="mt-5 d-flex justify-content-between">
                    <Button variant="outlined" color="info" size="large" onClick={handleCreateUser}>
                        Add
                    </Button>
                    <Button variant="outlined" color="secondary" size="large" onClick={handleSubmit}>
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UserDialog;
