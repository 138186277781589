import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyCard from "../../components/company-card/company-card";
import CompanyFilter from "../../components/company-filter/company-filter";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import Settings from "../../components/settings/settings";
import ApiService from "../../services/api.service";

const CompanyPage = () => {
    const { company_id } = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [filteredDatas, setFilteredDatas] = useState<null | { [index: string]: any }>(null || {});

    const getApiData = useCallback(() => {
        ApiService.getCompanyForm(company_id).then(
            (res) => {
                setLoading(false);
                setFilteredDatas(res.data.result.data);
            },
            (err) => {
                console.error(err);
            }
        );
    }, [company_id]);

    useEffect(() => {
        setLoading(true);
        getApiData();
    }, [getApiData]);

    useEffect(() => {
        document.title = "Forms Portal | Dashboard"
    }, []);

    return (
        <>
            <div className="place-holder"></div>
            <div className="px-md-4">
                {loading && <LoadingSpinner />}
                <div className="d-flex justify-content-between">
                    <CompanyFilter />
                    <Settings/>
                </div>
                <div className="row mt-4">
                    {filteredDatas &&
                        filteredDatas.length > 0 &&
                        filteredDatas.map((item: any) => {
                            return <CompanyCard key={item.id} data={item} />;
                        })}
                </div>
            </div>
        </>
    );
};

export default CompanyPage;
