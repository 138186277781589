import { TextField, Button } from "@mui/material"
import { useState } from "react"

interface SimpleDialogProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    role: string;
    defaultSubject: string;
    defaultBody: string;
    onChange: any;
    openEmailTemplate: any;
    handleSubmit: any;
}

const TabPanel = ({ children, value, index, role, defaultSubject, defaultBody, onChange, openEmailTemplate, handleSubmit }: SimpleDialogProps) => {

    const [editing, setEditing] = useState<Boolean>(false)

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`email-tab-${index}`}
        >
            <div className="row my-4">
                <div className="col-3">{role} Email Subject</div>
                <div className="col-8">
                    <TextField 
                        fullWidth 
                        name={`email_${role.toLowerCase()}_subject`} 
                        variant="standard" 
                        defaultValue={defaultSubject} 
                        onChange={onChange} 
                    />
                </div>
            </div>
            {editing && <div className="row my-4">    
                <TextField
                    name={`email_${role.toLowerCase()}_body_html`}
                    label={`${role} Email Contents`}
                    fullWidth
                    multiline
                    maxRows={20}
                    variant="outlined"
                    defaultValue={defaultBody}
                    onChange={onChange}
                />
            </div>}
            {/* Need to change justify-content from between to end depending on whether the Edit Template button is showing */}
            <div className={`d-flex ${editing ? 'justify-content-end' : 'justify-content-between'}`}>
                {!editing && <Button variant="outlined" color="info" size="large" onClick={(e) => {setEditing(true)}}>
                    {`Edit ${role} Email Template`}
                </Button>}
                <div className="d-flex">
                    <div className="mx-1">
                        <Button variant="outlined" color="secondary" size="large" onClick={(e) => openEmailTemplate(role)}>
                            {`Preview ${role} Email Template`}
                        </Button>
                    </div>
                    <div className="mx-1">
                        <Button variant="outlined" color="secondary" size="large" onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabPanel