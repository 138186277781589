import { useCallback, useEffect, useState } from "react";
import EnhancedTable from "../../components/mui-table/mui-table";
import ExportCsv from "../../components/export-csv/export-csv";
import TableDialog from "../../components/table-dialog/table-dialog";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import { useParams } from "react-router-dom";
import ApiService from "../../services/api.service";
import "./form.styles.css";
import TableSearch from "../../components/table-search/table-search";
import PopupMessageComponent from "../../components/popup-message/popup-message";
import ManageUsers from "../../components/manage-users/manage-users";
import ManageFormOptions from "../../components/manage-form-options/manage-form-options";
import TableSort from "../../components/table-sort/table-sort";
import Settings from "../../components/settings/settings";
import CompanyFilter from "../../components/company-filter/company-filter";
import ManageBlockedKeywords from "../../components/manage-blocked-keywords/manage-blocked-keywords";
import FormDescription from "../../components/form-description/form-description";
import ManageEmailTemplates from "../../components/manage-email-templates/manage-email-templates";

const Form = () => {
    const [originalDatas, setOriginalDatas] = useState<any[]>([]);
    const [filteredDatas, setFilteredDatas] = useState<any[]>([]);
    const [formInfo, setFormInfo] = useState<any>();
    const [formName, setFormName] = useState<string>("");
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState<null | { [index: string]: any }>(null || {});
    const [loading, setLoading] = useState<boolean>(false);
    const [tableController, setTableController] = useState<any>({
        page: 0,
        rowsPerPage: 100
    })
    const [popupMessage, setPopupMessage] = useState({
        title: "",
        content: "",
    });

    const { form_id, company_id } = useParams();

    const getApiData = useCallback(() => {
        ApiService.getDataById(form_id, (tableController.page * tableController.rowsPerPage) + 1, (tableController.page + 1) * tableController.rowsPerPage).then(
            (res) => {
                setLoading(false);
                setFilteredDatas(res.data.result.data);
                setOriginalDatas(res.data.result.data);
            },
            (err) => {
                console.error(err);
                setPopupOpen(true);
                setPopupMessage({
                    title: "Oops...",
                    content: "Some errors occurred while getting the form data. Please try again later.",
                });
            }
        );

        ApiService.getFormInfoById(form_id).then(
            (res) => {
                setFormInfo(res.data.result.data);
            },
            (err) => {
                console.error(err);
            }
        );
    }, [form_id, tableController]);

    useEffect(() => {
        setLoading(true);
        setFormName("form" + form_id);
        getApiData();
    }, [getApiData, form_id]);

    useEffect(() => {
        formInfo && (document.title = "Forms Portal | " + formInfo.name)
    }, [formInfo]);

    const tableHeader: any[] = [];
    if (filteredDatas && filteredDatas[0]) {
        for (const key in filteredDatas[0]) {
            if (key !== "ip_address" && key !== "status_id" && key !== "created_at" && key !== "updated_at" && key !== "deleted_at") {
                tableHeader.push({ id: key, numeric: false, label: (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " "), render: (data: any) => <div>{data.row[key]}</div> });
            }
        }
    }

    const sortOptions: any[] = [];
    if (filteredDatas && filteredDatas[0]) {
        for (const key in filteredDatas[0]) {
            if (key !== "ip_address" && key !== "status_id" && key !== "created_at" && key !== "updated_at" && key !== "deleted_at") {
                sortOptions.push({ id: key, value: key, name: (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " ") });
            }
        }
    }

    const newTableHeader = tableHeader;
    newTableHeader.push({
        id: "view",
        numeric: false,
        label: "View",
        render: (data: any) => (
            <div
                onClick={() => {
                    setDialogOpen(true);
                    setDialogData(data.row);
                }}
                className="link-dark cursor-pointer"
            >
                Details
            </div>
        ),
    });

    return (
        <>
            {loading && <LoadingSpinner />}
            <div className="place-holder"></div>
            <div className="d-flex justify-content-between">
                <CompanyFilter />
                <Settings />
            </div>
            {formInfo && formInfo.name && <div className="my-4">
                <div className="fs-24 fw-bold">{formInfo.name}</div>
                {formInfo.description && <FormDescription description={formInfo.description}/>}
            </div>}
            {(formInfo && filteredDatas && filteredDatas.length) ? (
                <>
                    {/* Form Top Bar */}
                    <div className="d-flex justify-content-between">
                        {/* Tool Bar */}
                        <div className="d-flex align-items-center flex-wrap">
                            <ExportCsv data={filteredDatas} formName={formName} />
                            <ManageBlockedKeywords company_id={company_id}/>
                            <ManageFormOptions form_info={formInfo} />
                            <ManageEmailTemplates form_info={formInfo} />
                            <ManageUsers form_info={formInfo} />
                            <TableSort form_id={form_id} sortOptions={sortOptions} setFilteredDatas={setFilteredDatas} setLoading={setLoading} />
                        </div>
                        {/* Search Bar */}
                        <div className="me-4">
                            <TableSearch setFilteredDatas={setFilteredDatas} originalDatas={originalDatas} headers={newTableHeader} />
                        </div>
                    </div>

                    {/* Form */}
                    <EnhancedTable header={newTableHeader} tableDatas={filteredDatas} setDialogData={setDialogData} tableController={tableController} setTableController={setTableController} totalRows={formInfo.total_records_count} loading={loading} setLoading={setLoading} />

                    {/* Form Row Details */}
                    <TableDialog open={dialogOpen} onClose={() => setDialogOpen(false)} data={dialogData} />
                </>
            ) : loading ? (
                "Loading..."
            ) : (
                "No data"
            )}
            {/* Error Message */}
            <PopupMessageComponent message={popupMessage} open={popupOpen} setOpen={setPopupOpen} />
        </>
    );
};

export default Form;
