import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, TextField, Button } from "@mui/material";
import ApiService from "../../services/api.service";

export interface SimpleDialogProps {
    open: boolean;
    data?: any;
    setDialogOpen: (dialogOpen: boolean) => void;
}

const FormInfoDialog = ({ open, data, setDialogOpen }: SimpleDialogProps) => {
    const [formValues, setFormValues] = useState<{ [index: string]: any }>({ id: data ? data.id : "" });

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        const transmissionData = new URLSearchParams();
        for (const name in formValues) {
            transmissionData.append(name, formValues[name] ? formValues[name] : "");
        }
        ApiService.updateFormInfo(transmissionData).then(
            (res) => {
                setDialogOpen(false);
            },
            (err) => {
                console.error(err);
            }
        );
    };

    return (
        <Dialog onClose={() => setDialogOpen(false)} open={open} fullWidth maxWidth="md">
            <DialogTitle>Manage Form Options</DialogTitle>
            <DialogContent>
                <div className="row my-4">
                    <div className="col-3">Form Name</div>
                    <div className="col-8">
                        <TextField fullWidth name="name" variant="standard" defaultValue={formValues.name ? formValues.name : data ? data.name : ""} onChange={handleFormChange} />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-3">Form Description</div>
                    <div className="col-8">
                        <TextField fullWidth name="description" variant="standard" defaultValue={formValues.description ? formValues.description : data ? data.description : ""} onChange={handleFormChange} />
                    </div>
                </div>
                {/* This has been moved to the Email Templates section */}
                
                {/* <div className="row mb-4">
                    <div className="col-3">Email Subject</div>
                    <div className="col-8">
                        <TextField fullWidth name="email_admin_subject" variant="standard" defaultValue={formValues.email_admin_subject ? formValues.email_admin_subject : data ? data.email_admin_subject : ""} onChange={handleFormChange} />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-3">Email From Address</div>
                    <div className="col-8">
                        <TextField fullWidth name="email_from_address" variant="standard" defaultValue={formValues.email_from_address ? formValues.email_from_address : data ? data.email_from_address : ""} onChange={handleFormChange} />
                    </div>
                </div> */}
                <div className="d-flex justify-content-end">
                    <Button variant="outlined" color="secondary" size="large" onClick={handleSubmit}>
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default FormInfoDialog;
