import axios from "axios";
import { BehaviorSubject } from "rxjs";
import { apiUrl, tokenName } from "./../environment";

export default class CoreService {
    public userToken: string = "";
    public userData = new BehaviorSubject({});
    public userCompany = new BehaviorSubject<any[]>([]);

    constructor() {
        this.RemoveTokenAfterExpiry();
        this.InitUserData();
        this.InitUserCompany();
    }

    RemoveTokenAfterExpiry = () => {
        const tokenString = localStorage.getItem(tokenName);
        if (tokenString) {
            const token = JSON.parse(tokenString);
            const now = new Date().getTime();
            const tokenExpiry = token.expireAt;
            if (now > tokenExpiry) {
                localStorage.removeItem(tokenName);
            }
        }
    };

    GetTokenFromLocalStorage = () => {
        const tokenString = localStorage.getItem(tokenName);
        if (!tokenString) {
            return null;
        } else {
            const tokenObject = JSON.parse(tokenString);
            const token = tokenObject.token;
            return token;
        }
    };

    SaveTokenToLocalStorage = (token: string) => {
        let tokenObject = {
            token: token,
            createdAt: 0,
            expireAt: 0,
        };
        const tokenCreated = new Date().getTime();
        tokenObject.createdAt = tokenCreated;
        // token expires in 2 hours
        const tokenExpiry = new Date().getTime() + 1000 * 60 * 60 * 2;
        tokenObject.expireAt = tokenExpiry;
        localStorage.setItem(tokenName, JSON.stringify(tokenObject));
    };

    LogOut() {
        const confirm = window.confirm(`Are you sure you want to log out?`);
        if (!confirm) {
            return;
        } else {
            localStorage.removeItem(tokenName);
            window.location.replace("/");
        }
    }

    InitUserData() {
        const token = this.GetTokenFromLocalStorage();
        if (token) {
            axios
                .get(`${apiUrl}/user`, {
                    baseURL: apiUrl,
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(
                    (res) => {
                        // console.log(res.data.result.data);
                        this.userData.next(res.data.result.data);
                    },
                    (err) => {
                        console.warn(err);
                        this.userData.next({});
                    }
                );
        }
    }

    InitUserCompany() {
        const token = this.GetTokenFromLocalStorage();
        if (token) {
            axios
                .get(`${apiUrl}/user/company`, {
                    baseURL: apiUrl,
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(
                    (res) => {
                        // console.log(res.data.result.data);
                        this.userCompany.next(res.data.result.data);
                    },
                    (err) => {
                        console.warn(err);
                        this.userCompany.next([]);
                    }
                );
        }
    }
}

export const coreService = new CoreService();
