import { Button } from "@mui/material";
import React from "react";
import { coreService } from "../../services/core.service";

const Settings = () => {
    return (
        <div>
            <Button variant="outlined" size="large" onClick={() => coreService.LogOut()}>
                Log out
            </Button>
        </div>
    );
};

export default Settings;
