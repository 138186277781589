import { Button } from "@mui/material";
import React, { useState } from "react";
import FormInfoDialog from "./form-dialog";

export interface Props {
    form_info: any;
}

const ManageFormOptions = ({ form_info }: Props) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleClick = () => {
        setDialogOpen(true);
    };

    return (
        <div className="mx-1">
            <Button variant="outlined" color="secondary" size="large" onClick={handleClick}>
                Manage Form Options
            </Button>
            <FormInfoDialog open={dialogOpen} data={form_info} setDialogOpen={setDialogOpen} />
        </div>
    );
};

export default ManageFormOptions;
