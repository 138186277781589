import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { coreService } from "../../services/core.service";
import { useParams } from "react-router-dom";

const CompanyFilter = () => {
    const { company_id } = useParams();

    const [menuItems, setMenuItems] = useState<any[]>([]);
    const [defaultCompanyId, setDefaultCompanyId] = useState<string>("0");

    const [SelectedMenuItem, setSelectedMenuItem] = useState(company_id);

    const handleInputChange = (e: any) => {
        const { value } = e.target;
        setSelectedMenuItem(value);
        window.location.replace(`/company/${value}`);
    };

    useEffect(() => {
        coreService.userCompany.subscribe((res) => {
            res[0] && setDefaultCompanyId(res[0].id);
            setMenuItems(res);
        });
    }, []);

    return (
        <div>
            <FormControl sx={{ width: 200 }} className="me-3">
                <Select variant="standard" value={SelectedMenuItem ? SelectedMenuItem : defaultCompanyId} label="Company" onChange={handleInputChange} name="company_id">
                    {menuItems &&
                        menuItems.length > 0 &&
                        menuItems.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default CompanyFilter;
