import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import "./mobile-nav.styles.css";
import ApiService from "../../services/api.service";

interface Props {
    currentUser: boolean;
}

const Nav = ({ currentUser }: Props) => {
    const { pathname } = useLocation();

    const [companyForms, setCompanyForms] = useState<any[]>([]);
    const [pathArray, setPathArray] = useState<any[]>([]);

    const getApiData = (company_id: number | string) => {
        ApiService.getCompanyForm(company_id).then(
            (res) => {
                setCompanyForms(res.data.result.data);
            },
            (err) => {
                console.error(err);
            }
        );
    };

    useEffect(() => {
        const pathArray = pathname.split("/");
        setPathArray(pathArray);
        if (pathname.includes("/company/")) {
            let company_id = pathname.replace("/company/", "");
            getApiData(company_id);
        }
    }, [pathname]);

    return (
        <>
            <div className="bgc-linear position-fixed w-100 zindex-5">
                <nav className="text-white d-block d-md-none">
                    <div className="w-100 d-flex justify-content-between align-items-center p-4 ps-2">
                        <div>
                            <img src={Logo} alt="logo" className="me-2" />
                            Forms Portal
                        </div>
                        {currentUser && (
                            <div>
                                <div data-bs-toggle="collapse" data-bs-target="#nav-dropdown" aria-expanded="false" aria-controls="nav-dropdown">
                                    <i className="fa fa-bars"></i>
                                </div>
                            </div>
                        )}
                    </div>
                </nav>
                <div className="collapse" id="nav-dropdown">
                    <div className="text-white dropdown-container">
                        <ul className="list-unstyled mb-auto">
                            {/* Dashboard */}
                            <li className={`mb-1 py-3 px-5 sidebar-list ${pathname.includes("dashboard") && "active"}`}>
                                <a className="link-white text-decoration-none fs-16 lh-2" href="/dashboard">
                                    Dashboard
                                </a>
                            </li>

                            {/* Form Overview */}
                            <li className={`mb-1 py-3 px-5 sidebar-list ${pathname.includes("company") && "active"}`}>
                                <div className="link-white text-decoration-none fs-16 lh-2">Form Overview</div>
                            </li>

                            {companyForms && companyForms.length
                                ? companyForms.map((item: any) => {
                                      return (
                                          <li className={`mb-1 p-3 sidebar-sublist ${pathArray[4] === item.id && "active"}`} key={item.id}>
                                              <a className="ps-5 link-white text-decoration-none fs-16 lh-2" href={`/company/${item.company_id}/form/${item.id}`}>
                                                  {item.name.length > 30 ? item.name.substring(0, 30) + " ..." : item.name}
                                              </a>
                                          </li>
                                      );
                                  })
                                : ""}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Nav;
