import { apiUrl } from "../environment";
import { authAxios } from "./interceptor";

export default class ApiService {
    static async getData(data: any) {
        return await authAxios.get(`${apiUrl}/data?form_id=${data.form_id}&record_start=${data.record_start}&record_end=${data.record_end}`);
    }

    static async getDataById(form_id: number | string | undefined, start: number, end: number) {
        return await authAxios.get(`${apiUrl}/data?form_id=${form_id}&record_start=${start}&record_end=${end}&orderby_column[]=id&orderby_value[]=desc`);
    }

    static async getUser() {
        return await authAxios.get(`${apiUrl}/user`);
    }

    static async getUserCompany() {
        return await authAxios.get(`${apiUrl}/user/company`);
    }

    static async getCompanyForm(form_id: number | string | undefined) {
        return await authAxios.get(`${apiUrl}/company/form?company_id=${form_id}`);
    }

    static async getFormInfoById(form_id: number | string | undefined) {
        return await authAxios.get(`${apiUrl}/form?id=${form_id}`);
    }

    static async updateFormInfo(data: any) {
        return await authAxios.post(`${apiUrl}/form`, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }

    static async updateFormUser(data: any) {
        return await authAxios.post(`${apiUrl}/form/notificationUsers`, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }

    static async getBlockedKeywordsByCompanyId(company_id: number | string | undefined) {
        return await authAxios.get(`${apiUrl}/blockedKeywords?company_id=${company_id}`);
    }

    static async updateBlockedKeywords(data: any) {
        return await authAxios.post(`${apiUrl}/blockedKeywords`, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    }

    static async orderDataByColumns(form_id: number | string | undefined, orderby_column: string, orderby_value: string) {
        return await authAxios.get(`${apiUrl}/data?form_id=${form_id}&record_start=1&record_end=999999999999&orderby_column[]=${orderby_column}&orderby_value[]=${orderby_value}`);
    }

    static async selectDataByColumns(form_id: number, select_column: string) {
        return await authAxios.get(`${apiUrl}/data?form_id=${form_id}&record_start=1&record_end=999999999999&select[]=${select_column}`);
    }

    static async filterDataByColumns(form_id: number, search_column: string, search_value: string) {
        return await authAxios.get(`${apiUrl}/data?form_id=${form_id}&record_start=1&record_end=999999999999&search_column[]=${search_column}&search_value[]=${search_value}`);
    }
}

