import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ApiService from "../../services/api.service";

export interface SimpleDialogProps {
    open: boolean;
    setDialogOpen: (value: boolean) => void;
    data?: any;
    company_id: any
}

const KeywordsDialog = ({ setDialogOpen, open, data, company_id }: SimpleDialogProps) => {
    const [keywordsArray, setKeywordsArray] = useState<any[]>([]);
    const [formValues, setFormValues] = useState<{ [index: string]: any }>({});

    useEffect(() => {
        data && setKeywordsArray(data);
    }, [data]);

    useEffect(() => {
        let initialFormValues: any = {};
        if (data && data.length) {
            for (let i = 0; i < data.length; i++) {
                initialFormValues["keyword" + (i + 1)] = data[i];
            }
        }
        setFormValues(initialFormValues);
    }, [data]);

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleCreateKeywords = () => {
        setKeywordsArray([...keywordsArray, ""]);
    };

    const handleSubmit = () => {
        const transmissionData = new URLSearchParams();

        transmissionData.append("company_id", company_id);

        for (const name in formValues) {
            if (formValues[name]) {
                transmissionData.append("keyword[]", formValues[name] ? formValues[name] : "");
            }
        }

        ApiService.updateBlockedKeywords(transmissionData).then(
            (res) => {
                setDialogOpen(false);
            },
            (error) => {
                console.error(error);
            }
        );
    };

    return (
        <Dialog onClose={() => setDialogOpen(false)} open={open} fullWidth maxWidth="sm">
            <DialogTitle>Manage Blocked Keywords</DialogTitle>
            <DialogContent>
                {keywordsArray && keywordsArray.length
                    ? keywordsArray.map((item: any, index: number) => {
                          return (
                              <div className="row my-4" key={index}>
                                  <div className="col-4">Blocked Keyword {index + 1}</div>
                                  <div className="col-7">
                                      <TextField fullWidth name={`keyword${index + 1}`} variant="standard" defaultValue={formValues.keyword + index + 1 ? formValues.keyword + index + 1 : item ? item : ""} onChange={handleFormChange} />
                                  </div>
                              </div>
                          );
                      })
                    : "No blocked keywords yet"}
                <div className="mt-5 d-flex justify-content-between">
                    <Button variant="outlined" color="info" size="large" onClick={handleCreateKeywords}>
                        Add
                    </Button>
                    <Button variant="outlined" color="secondary" size="large" onClick={handleSubmit}>
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default KeywordsDialog;
