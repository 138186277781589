import React, { useEffect, useState } from "react";
import Settings from "../../components/settings/settings";
import { coreService } from "../../services/core.service";

const ProfilePage = () => {
    const [userInfo, setUserInfo] = useState<any>(null);

    useEffect(() => {
        coreService.userData.subscribe((res) => {
            setUserInfo(res);
        });
    }, []);

    return (
        <>
            <div className="place-holder"></div>
            <div className="d-flex justify-content-end">
                <Settings />
            </div>
            <div>Profile</div>
            <div className="">
                <div>Name: {userInfo && userInfo.name}</div>
                <div>Email: {userInfo && userInfo.email}</div>
            </div>
        </>
    );
};

export default ProfilePage;
