import "./company-card.styles.css";

interface Props {
    data: any;
}

const CompanyCard = ({ data }: Props) => {
    const calculateClassName = (num1: number, num2: number) => {
        if (num1 === num2) {
            return "txt-default";
        }
        if (num1 > num2) {
            return "txt-secondary positive";
        }
        return "txt-danger negative";
    };

    const calculateNumber = (num1: number, num2: number) => {
        if (num1 === num2) {
            return "--";
        }
        if (num2 === 0) {
            return num1 * 100 + " %";
        }
        const a = Math.abs(Math.round(((num1 - num2) / num2) * 100));
        return a + " %";
    };
    return (
        <div className="col-12 col-xl-6 col-xxl-4 mb-4">
            <div className="company-card p-md-4 p-3 h-100 d-flex flex-column justify-content-between">
                <div className="content">
                    <div className="fw-500">{data.name}</div>
                    <div className="data p-md-4 p-2 my-3">
                        <div className="d-flex align-items-center justify-content-evenly">
                            {/* 7 Days */}
                            <div className="d-flex flex-column align-items-center">
                                <div className="time-period text-white fs-14">Last 7 Days</div>
                                <div className="count text-white fs-38 fw-bold">{data.stats["7_days"].count_current_period}</div>
                                <div className={`${calculateClassName(data.stats["7_days"].count_current_period, data.stats["7_days"].count_previous_period)} change`}>{calculateNumber(data.stats["7_days"].count_current_period, data.stats["7_days"].count_previous_period)}</div>
                            </div>
                            {/* Divider */}
                            <div className="divider"></div>
                            {/* 30 Days */}
                            <div className="d-flex flex-column align-items-center">
                                <div className="time-period text-white fs-14">Last 30 Days</div>
                                <div className="count text-white fs-38 fw-bold">{data.stats["30_days"].count_current_period}</div>
                                <div className={`${calculateClassName(data.stats["30_days"].count_current_period, data.stats["30_days"].count_previous_period)} change`}>{calculateNumber(data.stats["30_days"].count_current_period, data.stats["30_days"].count_previous_period)}</div>
                            </div>
                            {/* Divider */}
                            <div className="divider"></div>
                            {/* 180 Days */}
                            <div className="d-flex flex-column align-items-center">
                                <div className="time-period text-white fs-14">Last 6 Months</div>
                                <div className="count text-white fs-38 fw-bold">{data.stats["180_days"].count_current_period}</div>
                                <div className={`${calculateClassName(data.stats["180_days"].count_current_period, data.stats["180_days"].count_previous_period)} change`}>{calculateNumber(data.stats["180_days"].count_current_period, data.stats["180_days"].count_previous_period)}</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="break-word">{parse(data.description.length > 50 ? data.description.substring(0, 150) + "..." : data.description)}</div> */}
                </div>
                <div className="notification-email mb-4">
                    <div className="fs-14">Notification emails sent to:</div>
                    <div className="break-word fw-bold fs-14">{data.admin_notifications && data.admin_notifications.length ? data.admin_notifications.map((item: any) => item.email).join(", ") : "--"}</div>
                </div>
                <div className="link">
                    <div className="d-flex justify-content-between">
                        {data.url ? (
                            <a href={data.url} target="_blank" rel="noreferrer" className="link-secondary text-decoration-none d-flex align-items-center">
                                Form
                            </a>
                        ) : (
                            <div></div>
                        )}
                        <a href={`/company/${data.company_id}/form/${data.id}`} target="_blank" rel="noreferrer" className="link-secondary text-decoration-none d-flex align-items-center">
                            Report <i className="fa fa-arrow-right fs-12 ms-1"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyCard;
